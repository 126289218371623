import styled from 'styled-components';
import { TextField, Grid } from '@material-ui/core';
import { Form } from 'formik';

export const StyledForm = styled(Form)`
  display: inline-block;
  margin: 0 auto;
`;

export const StyledTextField = styled(TextField)`
  min-width: 300px !important;
  width: 100%;
  margin: 0.5rem 0 !important;

  & > * {
    font-size: 16px !important;
  }
`;

export const StyledMultilineTextField = styled(TextField)`
  min-width: 300px !important;
  max-width: 100%;
  width: 500px;
  margin: 0.5rem 0 !important;

  & > * {
    font-size: 16px !important;
  }
`;

export const FormSection = styled.div`
  padding: 2rem 0;
  margin: 0 auto;
`;

export const AboutYouFormSection = styled.div`
  display: inline-block;
  margin: 0 auto;
  text-align: left;
`;

export const AboutYouGridItem = styled(Grid)`
  text-align: center;
`;

export const IllustrationGridItem = styled(Grid)`
  align-items: center;
  display: flex;
`;
