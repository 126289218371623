import React from 'react';
import { Header } from 'components/theme';
import { Layout, SEO } from 'components/common';
import { ReferralForm } from 'components/giftcardreferral';
import { Typography, Container, Grid } from '@material-ui/core';

export default () => (
  <Layout>
    <SEO type="giftcards" />
    <Header product="giftcards" />
    <Container>
      <Grid container spacing={2} style={{ paddingTop: '3rem' }}>
        <Grid xs={12} item style={{ textAlign: 'center' }}>
          <Typography variant="h2" component="h1">
            Refer a Business
          </Typography>
          <Typography variant="body1" component="p">
            Know of a business you'd like to see on Paneau's gift card or menu platform?
            <br /> Fill out this form and we'll contact them to let them know
            they have customers waitisng!
          </Typography>
        </Grid>
      </Grid>
      <ReferralForm />
    </Container>
  </Layout>
);
