import React, { useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Error, Center } from 'components/common/Form/styles';
import { Typography, Grid, Hidden, Snackbar } from '@material-ui/core';
import LoadingButton from 'components/common/LoadingButton';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  StyledTextField,
  FormSection,
  StyledMultilineTextField,
  StyledForm,
  AboutYouGridItem,
  AboutYouFormSection,
  IllustrationGridItem,
} from './styles';
import BusinessOwnerIllustration from 'assets/illustrations/business-owner.inline.svg';
import queryString from 'query-string';
import Alert from '@material-ui/lab/Alert';

interface Referral {
  amount?: number;
  businessName: string;
  businessOwnerName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city: string;
  state: string;
  phone?: string;
  name: string;
  message?: string;
}

const writeData = (referral: Referral) => {
  var body = {
    'Business Name': referral.businessName,
    "Business Owner's Name": referral.businessOwnerName,
    'Address Line 1': referral.addressLine1,
    'Address Line 2': referral.addressLine2,
    City: referral.city,
    State: referral.state,
    'Phone Number': referral.phone,
    'Customer Name': referral.name,
    'Customer Message': referral.message,
  };

  return fetch(
    `${process.env.GATSBY_REFERRAL_FORM_URL}?${queryString.stringify(body)}`,
    { method: 'GET' }
  );
};

export const ReferralForm: React.FC = () => {
  const [errorOnSubmit, setErrorOnSubmit] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setShowSnackbar(false);
  };

  return (
    <>
      <Formik
        initialValues={
          {
            businessName: '',
            city: '',
            state: '',
          } as Referral
        }
        validationSchema={Yup.object().shape({
          businessName: Yup.string().required(
            'Business name field is required'
          ),
          city: Yup.string().required('City field is required'),
          state: Yup.string().required('State field is required'),
          phone: Yup.string().test(
            'phone-valid',
            'Phone number must be valid.',
            function(value) {
              return (
                !value || !!parsePhoneNumberFromString(value, 'US')?.isValid()
              );
            }
          ),
        })}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          console.log('UHHH SUBMIT?');
          try {
            setSubmitting(true);
            setErrorOnSubmit(false);
            await writeData(data);
            setShowSnackbar(true);
            resetForm();
          } catch (err) {
            setSubmitting(false);
            setErrorOnSubmit(true);
          }
        }}
      >
        <ReferralFormBody errorOnSubmit={errorOnSubmit} />
      </Formik>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="success"
        >
          Thank you. Your response was recorded successfully.
        </Alert>
      </Snackbar>
    </>
  );
};

const ReferralFormBody: React.FC<{ errorOnSubmit: boolean }> = props => {
  const formikContext = useFormikContext<Referral>();

  const businessNameError =
    formikContext.touched.businessName && formikContext.errors.businessName;
  const cityError = formikContext.touched.city && formikContext.errors.city;
  const stateError = formikContext.touched.state && formikContext.errors.state;

  return (
    <StyledForm
      name="business-signup"
      method="post"
      onSubmit={formikContext.handleSubmit}
    >
      <Grid container spacing={6} style={{ paddingTop: '3rem' }}>
        <Grid item sm={12} md={5} lg={4}>
          <FormSection>
            <Typography variant="body1">About the business:</Typography>
            <div>
              <StyledTextField
                size="small"
                required
                id="businessName"
                label="Business Name"
                aria-label="business name"
                type="text"
                name="businessName"
                error={Boolean(businessNameError)}
                helperText={businessNameError}
                onChange={formikContext.handleChange}
                onBlur={formikContext.handleBlur}
                value={formikContext.values.businessName}
                variant="outlined"
              />
            </div>
            <div>
              <StyledTextField
                size="small"
                id="businessOwnerName"
                label="Business Owner Name (optional)"
                aria-label="business owner name"
                type="text"
                name="businessOwnerName"
                onChange={formikContext.handleChange}
                onBlur={formikContext.handleBlur}
                value={formikContext.values.businessOwnerName}
                variant="outlined"
              />
            </div>
            <div>
              <StyledTextField
                size="small"
                id="phone"
                label="Phone Number (optional)"
                aria-label="phone number"
                type="text"
                name="phone"
                onChange={formikContext.handleChange}
                onBlur={formikContext.handleBlur}
                value={formikContext.values.phone}
                variant="outlined"
              />
            </div>
            <div>
              <StyledTextField
                size="small"
                id="addressLine1"
                label="Address Line 1 (optional)"
                aria-label="address line 1"
                type="text"
                name="addressLine1"
                onChange={formikContext.handleChange}
                onBlur={formikContext.handleBlur}
                value={formikContext.values.addressLine1}
                variant="outlined"
              />
            </div>
            <div>
              <StyledTextField
                size="small"
                id="addressLine2"
                label="Address Line 2 (optional)"
                aria-label="address line 2"
                type="text"
                name="addressLine2"
                onChange={formikContext.handleChange}
                onBlur={formikContext.handleBlur}
                value={formikContext.values.addressLine2}
                variant="outlined"
              />
            </div>
            <div>
              <StyledTextField
                size="small"
                required
                id="city"
                label="City"
                aria-label="city"
                type="text"
                name="city"
                error={Boolean(cityError)}
                helperText={cityError}
                onChange={formikContext.handleChange}
                onBlur={formikContext.handleBlur}
                value={formikContext.values.city}
                variant="outlined"
              />
            </div>
            <div>
              <StyledTextField
                size="small"
                required
                id="state"
                label="State"
                aria-label="state"
                type="text"
                name="state"
                error={Boolean(stateError)}
                helperText={stateError}
                onChange={formikContext.handleChange}
                onBlur={formikContext.handleBlur}
                value={formikContext.values.state}
                variant="outlined"
              />
            </div>
          </FormSection>
        </Grid>
        <IllustrationGridItem item sm={6} md={7} lg={8}>
          <Hidden only={['sm', 'xs']}>
            <BusinessOwnerIllustration style={{ maxWidth: '100%' }} />
          </Hidden>
        </IllustrationGridItem>
        <AboutYouGridItem item sm={12} md={12}>
          <AboutYouFormSection>
            <Typography variant="body1">About you:</Typography>
            <div>
              <StyledTextField
                size="small"
                id="name"
                label="Your Name (optional)"
                type="text"
                name="name"
                aria-label="your name"
                onChange={formikContext.handleChange}
                onBlur={formikContext.handleBlur}
                value={formikContext.values.name}
                variant="outlined"
              />
            </div>
            <div>
              <StyledMultilineTextField
                size="small"
                multiline
                id="message"
                label="Message (optional)"
                type="text"
                name="message"
                aria-label="message to the owner"
                onChange={formikContext.handleChange}
                onBlur={formikContext.handleBlur}
                value={formikContext.values.message}
                variant="outlined"
                rows={4}
              />
            </div>
          </AboutYouFormSection>
          <Center>
            <LoadingButton
              loading={formikContext.isSubmitting}
              variant="contained"
              color="secondary"
              type="submit"
              disabled={formikContext.isSubmitting}
            >
              Refer Business
            </LoadingButton>
            {props.errorOnSubmit && (
              <div>
                <Error>An error occurred. Please try again.</Error>
              </div>
            )}
          </Center>
        </AboutYouGridItem>
      </Grid>
    </StyledForm>
  );
};

export default ReferralForm;
